<template>
    <v-textarea
        :placeholder="config.placeholder"
        :label="config.label"
        v-model="model[config.key]"
        v-on="config.customEvents"
        :rules="config.rules"
        :required="config.required"
        :type="config.type"
        :dense="config.dense"
        :error-messages="errorMessages"
        :readonly="isReadonly"
        :rows="config.numberRows">
    </v-textarea>

</template>

<script>

export default {
    name: 'FormTextArea',
    props: ['config', 'model', 'errors'],
    computed: {
        errorMessages() {
            if (this.errors) {
                return this.errors[this.config.key];
            }
            return '';
        },
        isReadonly() {
            return this.config.isReadonly && this.config.isReadonly(this.model);
        }
    }
}
</script>
